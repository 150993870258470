import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { DEFAULT_COUNTRY_CODE_AU } from '~/constants/countries'
import {
  ForterEvent,
  PageProviderContext,
  PageProviderProps,
} from './PageProvider.types'

export const MIN_DESKTOP_WIDTH = 1024
export const MIN_TABLET_WIDTH = 768

export const PageContext = createContext<PageProviderContext>(null)

export const PageProvider = ({ children, ...page }: PageProviderProps) => {
  const [forterToken, setForterToken] = useState<string>()
  const [pageHeader, setPageHeader] = useState<HTMLElement>()
  const [pageWidth, setPageWidth] = useState<number>()
  const [pageHeaderHeight, setPageHeaderHeight] = useState<number>()
  const [sitewideBannerHeight, setSitewideBannerHeight] = useState<number>()
  const [pageHeaderMobileDrawerHeight, setPageHeaderMobileDrawerHeight] =
    useState<number>()
  const [pageHeaderFormHeight, setPageHeaderFormHeight] = useState<number>()

  const pageHeaderRef = useCallback((node: HTMLElement) => {
    if (node == null) {
      return
    }

    setPageHeader(node)
  }, [])

  const getSitewideBannerEl = useCallback(() => {
    return pageHeader
      ?.getElementsByClassName('sitewideBanner')
      ?.item(0) as HTMLElement
  }, [pageHeader])

  const getMobileHeaderDrawerEl = useCallback(() => {
    return pageHeader
      ?.getElementsByClassName('mobileHeaderDrawer')
      ?.item(0) as HTMLElement
  }, [pageHeader])

  const getHeaderFormEl = useCallback(() => {
    return pageHeader
      ?.getElementsByClassName('headerForm')
      ?.item(0) as HTMLElement
  }, [pageHeader])

  useEffect(() => {
    let observer: ResizeObserver

    if (pageHeader != null) {
      observer = new ResizeObserver((entries) => {
        const target = entries[0].target
        const sitewideBanner = getSitewideBannerEl()
        const mobileHeaderDrawer = getMobileHeaderDrawerEl()
        const headerForm = getHeaderFormEl()

        setPageHeaderHeight(target.getBoundingClientRect().height ?? 0)
        setSitewideBannerHeight(
          sitewideBanner?.getBoundingClientRect()?.height ?? 0
        )
        setPageHeaderMobileDrawerHeight(
          mobileHeaderDrawer?.getBoundingClientRect()?.height ?? 0
        )
        setPageHeaderFormHeight(headerForm.getBoundingClientRect().height ?? 0)
        setPageWidth(window.innerWidth)
      })

      observer.observe(pageHeader)
    }

    return () => {
      observer?.disconnect()
    }
  }, [
    pageHeader,
    setPageHeaderHeight,
    setSitewideBannerHeight,
    getHeaderFormEl,
    getMobileHeaderDrawerEl,
    getSitewideBannerEl,
  ])

  useEffect(() => {
    const forterListener = (evnt: ForterEvent) => {
      if (evnt.detail) {
        const token = evnt.detail
        setForterToken(token)
      }
    }

    document.addEventListener('ftr:tokenReady', forterListener)
    return () => {
      document.removeEventListener('ftr:tokenReady', forterListener)
    }
  }, [])

  const value = useMemo(() => {
    return {
      ...page,
      countryCode: page.countryCode?.toUpperCase() || DEFAULT_COUNTRY_CODE_AU,
      contactUsLink: page.contactUsLink,
      faqLink: page.faqLink,
      forterToken,
      pageHeaderRef,
      pageHeaderHeight,
      sitewideBannerHeight,
      pageWidth,
      pageHeaderMobileDrawerHeight,
      pageHeaderFormHeight,
      getSitewideBannerEl,
      getMobileHeaderDrawerEl,
      getHeaderFormEl,
    }
  }, [
    forterToken,
    page,
    pageHeaderRef,
    pageWidth,
    pageHeaderHeight,
    sitewideBannerHeight,
    pageHeaderMobileDrawerHeight,
    pageHeaderFormHeight,
    getSitewideBannerEl,
    getMobileHeaderDrawerEl,
    getHeaderFormEl,
  ])
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
