import * as gtag from '~/lib/gtag'
import { formatPriceForDataLayer } from '../gtm/helpers'

// Mapping of page types to ecomm_pagetype values
const pageTypeMapping = {
  home: 'home',
  category: 'category',
  product: 'product',
  checkout: null, // Return null to skip sending events for 'checkout' page type
  purchase: null, // Return null to skip sending events for 'purchase' page type
  other: 'other',
}

// Function to generate the payload based on page type
const createPayload = (pageType, gtagId, pageProps) => {
  const basePayload = { send_to: gtagId, ecomm_pagetype: pageType }

  if (pageType === 'product') {
    // Add additional fields specific to the product page
    return {
      ...basePayload,
      ecomm_prodid: pageProps?.page?.head?.product?.sku,
      ecomm_totalvalue: formatPriceForDataLayer(
        pageProps?.page.head.product.offer.price
      ),
    }
  }

  return basePayload
}

export const handleLoadEvent = (pageProps, gtagId, router) => {
  const { pageType } = pageProps?.page.head || {}

  let ecommPageType

  if (router.asPath?.includes('/search')) {
    ecommPageType = 'searchresults'
  } else if (pageTypeMapping[pageType] === null) {
    ecommPageType = null
  } else if (pageTypeMapping[pageType] !== undefined) {
    ecommPageType = pageTypeMapping[pageType]
  } else {
    ecommPageType = 'other'
  }

  // If ecommPageType is null, skip sending the event (e.g., for 'purchase' page type)
  if (!ecommPageType) {
    return
  }

  // Create and dispatch the event with the appropriate payload
  const payload = createPayload(ecommPageType, gtagId, pageProps)
  gtag.pageview({
    action: 'page_view',
    payload,
  })
}
